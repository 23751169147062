import { FC } from "react";
import {
  ChatText,
  Envelope,
  GeoAlt,
  PersonWorkspace,
  Telephone,
} from "react-bootstrap-icons";
import wesgan_logo from "../../assets/logo-orange.png";
import footerDecoImg from "../../assets/img/decorate/h4_footer_shape.png";
import "./Footer.scss";

const Footer: FC = () => {
  return (
    <footer className="text-start text-white main-footer">
      <div className="img-deco">
        <img src={footerDecoImg} alt="" />
      </div>
      <section className="">
        <div className="container pt-5">
          <div className="row mt-3">
            <div className="col-md-6 col-lg-5 col-xxl-4 mx-auto mb-4">
              <div className="d-flex align-items-center mb-4">
                <div className="d-inline-block">
                  <img
                    src={wesgan_logo}
                    alt="wesgan_logo"
                    className="logo-size"
                  />
                </div>
                <div className="d-inline-block padding-left-10">
                  <h2 className="fw-bolder">wesgan</h2>
                </div>
              </div>
              <div className="pb-3 footer-contact-info">
                <div className="d-inline">
                  <ChatText size={20} />
                </div>
                <div className="d-inline-block padding-left-10">
                  <a
                    href="mailto:suppharada.p@digitalai.co.th"
                    className="footer-contact-link"
                  >
                    suppharada.p@digitalai.co.th
                  </a>
                </div>
              </div>
              <div className="pb-3 footer-contact-info">
                <div className="d-inline">
                  <Envelope size={20} />
                </div>
                <div className="d-inline-block padding-left-10">
                  <a
                    href="mailto:sale@wesgan.com"
                    className="footer-contact-link"
                  >
                    sale@wesgan.com
                  </a>
                </div>
              </div>
              <div className="pb-3 footer-contact-info">
                <div className="d-inline">
                  <PersonWorkspace size={20} />
                </div>
                <div className="d-inline-block padding-left-10">
                  <a
                    href="mailto:support@wesgan.com"
                    className="footer-contact-link"
                  >
                    support@wesgan.com
                  </a>
                </div>
              </div>
              <div className="pb-3 footer-contact-info">
                <div className="d-inline">
                  <Telephone size={20} />
                </div>
                <div className="d-inline-block padding-left-10">
                  <a href="tel:+66659095690" className="footer-contact-link">
                    065-909-5690
                  </a>
                </div>
              </div>
              <div className="pb-3 footer-contact-info">
                <div className="d-inline">
                  <GeoAlt size={20} />
                </div>
                <div className="d-inline-block padding-left-10">
                  <a
                    href="https://maps.app.goo.gl/anX42gPMK4o4TF2L9"
                    target="_blank"
                    rel="noreferrer"
                    className="footer-contact-link"
                  >
                    Digital AI Company Limited, Chiang Mai, Thailand
                  </a>
                </div>
              </div>
            </div>

            {/* <div className="col-md-6 col-lg-3 mx-auto mb-4">
              <h4 className="fw-bold">Features</h4>
              <hr className="mb-4 mt-0 d-inline-block mx-auto underline-styled" />
              <div className="pb-5">
                <a href="/features" className="footer-page-link">
                  <h6>Feature #1</h6>
                </a>
              </div>
              <div className="pb-5">
                <a href="/features" className="footer-page-link">
                  <h6>Feature #2</h6>
                </a>
              </div>
              <div className="pb-5">
                <a href="/features" className="footer-page-link">
                  <h6>Feature #3</h6>
                </a>
              </div>
              <div className="pb-5">
                <a href="/features" className="footer-page-link">
                  <h6>Feature #4</h6>
                </a>
              </div>
            </div> */}
            <div className="col-md-4 col-lg-4">
              {/* <div className="col-md-3 col-lg-4 mx-auto mb-4"> */}
              <div className="mx-auto mb-4">
                <h4 className="fw-bold">Contact</h4>
                <hr className="mb-4 mt-0 d-inline-block mx-auto underline-styled" />

                <div className="pb-5">
                  <a href="/contact-us" className="footer-page-link">
                    <h6>Contact Us</h6>
                  </a>
                </div>
                {/* <div className="pb-5">
                <a href="#!" className="footer-page-link">
                  <h6>FAQs</h6>
                </a>
              </div>
              <div className="pb-5">
                <a href="#!" className="footer-page-link">
                  <h6>Careers</h6>
                </a>
              </div> */}
              </div>
              {/* </div> */}

              {/* <div className="col-md-3 col-lg-4 mx-auto mb-md-0 mb-4"> */}
              <div className="mx-auto mb-4">
                <h4 className="fw-bold">Links</h4>
                <hr className="mb-4 mt-0 d-inline-block mx-auto underline-styled" />
                <div className="pb-5">
                  <a href="/quotation" className="footer-page-link">
                    <h6>Quotation</h6>
                  </a>
                </div>
                <div className="pb-5">
                  <a href="/privacy-policy" className="footer-page-link">
                    <h6>Privacy Policy</h6>
                  </a>
                </div>
                {/* <div className="pb-5">
                <a href="#!" className="footer-page-link">
                  <h6>Support</h6>
                </a>
              </div> */}
                {/* <div className="pb-5">
                <a href="/demo" className="footer-page-link">
                  <h6>Demo</h6>
                </a>
              </div>
              <div className="pb-5">
                <a
                  href={"https://www.orimi.com/pdf-test.pdf"}
                  className="footer-page-link"
                  download
                  target="_blank"
                  rel="noreferrer"
                >
                  <h6>Brochure</h6>
                </a>
              </div> */}
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </section>
      <div className="text-center p-5 copyright-styled">
        Copyright ©2024 Digital AI Co., Ltd. All Rights Reserved.
      </div>
    </footer>
  );
};

export { Footer };
