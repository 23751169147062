import React, { FC } from "react";
import { MainLayout } from "../../layout/MainLayout";
import { Col, Container, Row } from "react-bootstrap";
import { gMapUrl } from "./googleMapLink";
import "./index.scss";
import Stripe from "components/stripe/stripe";
import DetailGroup from "./components/DetailGroup/DetailGroup";
import useAlert from "utils/hooks/useAlert";

const ContactPage: FC = () => {
  const address =
    "314 Moo.7 Suthep, Muang Chiang Mai, Chiang Mai Thailand 50200";
  const { fireToast } = useAlert();
  const handleCopyAddress = () => {
    navigator.clipboard.writeText(address).then(() => {
      fireToast("Address copied", "success", {
        timer: 2600,
        position: "bottom",
      });
    });
  };

  return (
    <MainLayout>
      <div className="contact-us__container">
        <Container>
          <Row className="my-4">
            <Col
              xs={{ span: 12, order: "last" }}
              lg={{ span: 12, order: "last" }}
              xl={{ span: 7, order: "first" }}
              className="p-3"
            >
              <div className="contact-us__mapframe">
                <iframe
                  src={gMapUrl}
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </Col>
            <Col
              xs={{ span: 12, order: "first" }}
              lg={{ span: 12, order: "first" }}
              xl={{ span: 5, order: "last" }}
              className="p-3"
            >
              <div className="contact-us__detail p-4">
                <div className="mb-4">
                  <h2 className="text-secondary">Contact Us</h2>
                  <Stripe />
                </div>

                <DetailGroup
                  title="Address"
                  icon={<i className="bi bi-geo-alt"></i>}
                >
                  <p className="mb-1">
                    <b>Digital AI Company Limited</b>
                  </p>
                  <div className="copy" onClick={handleCopyAddress}>
                    <p>{address}</p>
                  </div>
                </DetailGroup>

                <DetailGroup
                  title="Phone"
                  icon={<i className="bi bi-telephone"></i>}
                >
                  <p className="mb-1">
                    <b>Sale : </b>
                    <a href="tel:+66658986365">065-898-6365</a>
                  </p>
                  <p className="mb-1">
                    <b>Accountant : </b>
                    <a href="tel:+66659095690">065-909-5690</a>
                  </p>
                </DetailGroup>

                <DetailGroup
                  title="E-mail"
                  icon={<i className="bi bi-envelope-at"></i>}
                >
                  <p className="mb-1">
                    <b>Sale : </b>
                    <a href="mailto:sale@wesgan.com">sale@wesgan.com</a>
                  </p>
                  <p className="mb-1">
                    <b>Support : </b>
                    <a href="mailto:support@wesgan.com">support@wesgan.com</a>
                  </p>
                </DetailGroup>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </MainLayout>
  );
};

export { ContactPage };
