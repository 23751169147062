import React, { FC } from "react";
import { MainLayout } from "../../layout/MainLayout";
import "./index.scss";

const PrivacyPolicyPage: FC = () => {
  return (
    <MainLayout>
      <div className="privacy-main-body container">
        <div className="privacy-policy privacy-topic-container">
          <div className="privacy-header-text">
            <h2>นโยบายการคุ้มครองข้อมูลส่วนบุคคล</h2>
          </div>
          <div className="body-text">
            <p>
              เพื่อให้เป็นไปตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
              บริษัท ดิจิทอล เอไอ จำกัด ได้มีการปรับปรุงนโยบายคุ้มครอง
              ข้อมูลส่วนบุคคล โดยปรับปรุงข้อตกลงเงื่อนไขการให้บริการ
              และนโยบายคุ้มครองข้อมูลส่วนบุคคลบนหน้าเว็บไซต์ https://www.wesgan.com
              สำหรับการเข้าใช้งานระบบ Application
              รวมทั้งบริษัทได้จัดให้มีมาตรการรักษาความปลอดภัยของข้อมูลเพื่อประโยชน์ของผู้ใช้บริการตามที่
              พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคลกำหนดทุกประการ
            </p>
          </div>
        </div>
        <div className="personal-info-collection privacy-topic-container">
          <div className="privacy-header-text">
            <h4>1. คำนิยาม</h4>
          </div>
          <div className="body-text">
            <p className="privacy-indent">
              1.1 “บริษัทฯ” หมายถึง บริษัท ผู้พัฒนา Wesgan ชื่อภาษาไทย เวสแกน
              (บริษัท ดิจิทอล เอไอ จำกัด) รวมถึงผลิตภัณฑ์อื่นๆ ภายใต้ชื่อ เวสแกน
            </p>
            <p className="privacy-indent">1.2 “บุคคล” หมายถึง บุคคลธรรมดา</p>
            <p className="privacy-indent">
              1.3 “ข้อมูลส่วนบุคคล” หมายถึง
              ข้อมูลเกี่ยวกับบุคคลที่สามารถระบุตัวบุคคลได้ไม่ว่าทางตรงหรือทางอ้อม
              แต่ไม่รวมถึงข้อมูล ของผู้ถึงแก่กรรม
            </p>
            <p className="privacy-indent">
              1.4 “เจ้าของข้อมูลส่วนบุคคล” หมายถึง
              บุคคลซึ่งเป็นเจ้าของข้อมูลส่วนบุคคล
            </p>
            <p className="privacy-indent">
              1.5 “ประมวลผลข้อมูลส่วนบุคคล” หมายถึง การเก็บรวบรวม การใช้
              การเปิดเผย หรือ การโอนข้อมูลส่วนบุคคล
            </p>
            <p className="privacy-indent">
              1.6 “หน่วยงานฯ” หมายถึง
              หน่วยงานราชการซึ่งมีอำนาจบังคับใช้กฎหมายคุ้มครองข้อมูลส่วนบุคคลในประเทศไทย
            </p>
            <p>
              “ข้อมูลส่วนบุคคล” หมายความว่า
              ข้อมูลเกี่ยวกับบุคคลซึ่งทำให้สามารถระบุตัวบุคคลนั้นได้
              ไม่ว่าทางตรงหรือทางอ้อม แต่ไม่รวมถึงข้อมูลของผู้ถึงแก่กรรมโดยเฉพาะ
              อาทิ ชื่อ นามสกุล ชื่อเล่น ที่อยู่ หมายเลขโทรศัพท์
              เลขประจำตัวประชาชน เลขหนังสือเดินทาง เลขบัตรประกันสังคม
              เลขใบอนุญาตขับขี่ เลขประจำตัวผู้เสียภาษี เลขบัญชีธนาคาร
              เลขบัตรเครดิต ที่อยู่อีเมล (email address)
              <br />
              <br />
              ข้อมูลต่อไปนี้ไม่ใช่ข้อมูลส่วนบุคคล เช่น
              ข้อมูลสำหรับการติดต่อทางธุรกิจที่ไม่ได้ระบุถึงตัวบุคคล อาทิ
              ชื่อบริษัท ที่อยู่ของบริษัท เลขทะเบียนนิติบุคคลของบริษัท
              หมายเลขโทรศัพท์ของที่ทำงาน ที่อยู่อีเมล (email address)
              ที่ใช้ในการทำงาน ที่อยู่อีเมล (email address) กลุ่มของบริษัท เช่น
              info@company.co.th ข้อมูลนิรนาม (Anonymous Data)
              หรือข้อมูลแฝงที่ถูกทำให้ไม่สามารถระบุตัวบุคคลได้อีกโดยวิธีการทางเทคนิค
              (Pseudonymous Data) ข้อมูลผู้ถึงแก่กรรม เป็นต้น
              <br />
              <br />
              “ข้อมูลส่วนบุคคลอ่อนไหว” หมายถึง
              ข้อมูลที่เป็นเรื่องส่วนบุคคลโดยแท้ของบุคคล
              แต่มีความละเอียดอ่อนและอาจสุ่มเสี่ยงในการเลือกปฏิบัติอย่างไม่เป็นธรรม
              เช่น เชื้อชาติ เผ่าพันธุ์ ความคิดเห็นทางการเมือง ความเชื่อในลัทธิ
              ศาสนาหรือปรัชญา พฤติกรรมทางเพศ ประวัติอาชญากรรม ข้อมูลสุขภาพ
              ความพิการ ข้อมูลสหภาพแรงงาน ข้อมูลพันธุกรรม ข้อมูลชีวภาพ
              หรือข้อมูลอื่นใด
              ซึ่งกระทบต่อเจ้าของข้อมูลส่วนบุคคลในทำนองเดียวกันตามที่คณะกรรมการคุ้มครองข้อมูลส่วนบุคคลประกาศกำหนด
              <br />
              <br />
              “เจ้าของข้อมูลส่วนบุคคล” (Data Subject) หมายถึง
              ตัวบุคคลที่เป็นเจ้าของข้อมูลส่วนบุคคลนั้น
              แต่ไม่ใช่กรณีที่บุคคลมีความเป็นเจ้าของข้อมูล (Ownership)
              หรือเป็นผู้สร้างหรือเก็บรวบรวมข้อมูลนั้นเอง
              โดยเจ้าของข้อมูลส่วนบุคคลนี้จะหมายถึงบุคคลธรรมดาเท่านั้น
              และไม่รวมถึง “นิติบุคคล” (Juridical Person)
              ที่จัดตั้งขึ้นตามกฎหมาย เช่น บริษัท สมาคม มูลนิธิ หรือองค์กรอื่นใด
            </p>
            <p>ทั้งนี้ เจ้าของข้อมูลส่วนบุคคล ได้แก่ บุคคลดังต่อไปนี้</p>
            <p className="privacy-indent">
              1. เจ้าของข้อมูลส่วนบุคคลที่เป็นผู้บรรลุนิติภาวะ หมายถึง
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;1.1 บุคคลที่มีอายุตั้งแต่ 20
              ปีบริบูรณ์ขึ้นไป หรือ
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;1.2 ผู้ที่สมรสตั้งแต่อายุ 17
              ปีบริบูรณ์ขึ้นไป หรือ
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;1.3 ผู้ที่สมรสก่อนอายุ 17 ปี
              โดยศาลอนุญาตให้ทำการสมรส หรือ
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;1.4
              ผู้เยาว์ซึ่งผู้แทนโดยชอบธรรมให้ความยินยอมในการประกอบธุรกิจทางการค้าหรือธุรกิจอื่น
              หรือในการทำสัญญาเป็นลูกจ้างในสัญญาจ้างแรงงาน
              ในความเกี่ยวพันกับการประกอบธุรกิจหรือ
              การจ้างแรงงานข้างต้นให้ผู้เยาว์มีฐานะเสมือนดังบุคคลซึ่งบรรลุนิติภาวะแล้ว
              <br />
            </p>
            <p className="privacy-indent">
              ทั้งนี้ ในการให้ความยินยอมใดๆ
              เจ้าของข้อมูลส่วนบุคคลที่เป็นผู้บรรลุนิติภาวะสามารถให้ความยินยอมได้ด้วยตนเอง
            </p>
            <p className="privacy-indent">
              2. เจ้าของข้อมูลส่วนบุคคลที่เป็นผู้เยาว์ หมายถึง
              บุคคลที่อายุต่ำกว่า 20 ปีบริบูรณ์ และไม่ใช่ผู้บรรลุนิติภาวะตามข้อ
              1 ทั้งนี้ ในการให้ความยินยอมใดๆ
              จะต้องได้รับความยินยอมจากผู้ใช้อำนาจปกครองที่มีอำนาจกระทำการแทนผู้เยาว์ด้วย
            </p>
            <p className="privacy-indent">
              3. เจ้าของข้อมูลส่วนบุคคลที่เป็นคนเสมือนไร้ความสามารถ หมายถึง
              บุคคลที่ศาลสั่งให้เป็นคนเสมือนไร้ความสามารถ
              เนื่องจากมีกายพิการหรือมีจิตฟั่นเฟือนไม่สมประกอบ
              หรือประพฤติสุรุ่ยสุร่ายเสเพลเป็นอาจิณ หรือติดสุรายาเมา
              หรือมีเหตุอื่นใดทำนองเดียวกันนั้น
              จนไม่สามารถจะจัดทำการงานโดยตนเองได้
              หรือจัดกิจการไปในทางที่อาจจะเสื่อมเสียแก่ทรัพย์สินของตนเองหรือครอบครัว
              ทั้งนี้ ในการให้ความยินยอมใดๆ
              จะต้องได้รับความยินยอมจากผู้พิทักษ์ที่มีอำนาจกระทำการแทนคนเสมือนไร้ความสามารถนั้นก่อน
            </p>
            <p className="privacy-indent">
              4. เจ้าของข้อมูลส่วนบุคคลที่เป็นคนไร้ความสามารถ หมายถึง
              บุคคลที่ศาลสั่งให้เป็นคนไร้ความสามารถ เนื่องจากเป็นบุคคลวิกลจริต
              ทั้งนี้ ในการให้ความยินยอมใดๆ
              จะต้องได้รับความยินยอมจากผู้อนุบาลที่มีอำนาจกระทำการแทนคนไร้ความสามารถนั้นก่อน
            </p>
            <p>
              ทั้งนี้
              หากการขอความยินยอมจากเจ้าของข้อมูลส่วนบุคคลที่ไม่เป็นไปตามกฎหมายคุ้มครองข้อมูลส่วนบุคคลไม่มีผลผูกพันเจ้าของข้อมูลส่วนบุคคล
            </p>
            <p>
              “ผู้ควบคุมข้อมูลส่วนบุคคล” หมายความว่า
              บุคคลหรือนิติบุคคลซึ่งมีอำนาจหน้าที่ตัดสินใจเกี่ยวกับการเก็บรวบรวม
              ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล
            </p>
            <p>
              “ผู้ประมวลผลข้อมูลส่วนบุคคล” หมายความว่า
              บุคคลหรือนิติบุคคลซึ่งดำเนินการเกี่ยวกับการเก็บรวบรวม ใช้
              หรือเปิดเผยข้อมูลส่วนบุคคลตามคำสั่งหรือในนามของผู้ควบคุมข้อมูลส่วนบุคคล
              ทั้งนี้
              บุคคลหรือนิติบุคคลซึ่งดำเนินการดังกล่าวไม่เป็นผู้ควบคุมข้อมูลส่วนบุคคล
            </p>
          </div>
        </div>
        <div className="personal-info-collection privacy-topic-container">
          <div className="privacy-header-text">
            <h4>2. วัตถุประสงค์ในการประมวลผลข้อมูลส่วนบุคคล</h4>
          </div>
          <div className="body-text">
            <p>
              บริษัทฯ ใช้วิธีการเก็บรวบรวม ใช้ เปิดเผย
              ข้อมูลส่วนบุคคลด้วยวิธีการที่ชอบด้วยกฎหมายและเป็นธรรม
              โดยจัดเก็บข้อมูลส่วนบุคคลเท่าที่จำเป็น
              เพื่อใช้ในการติดต่อให้บริการ ประชาสัมพันธ์
              หรือให้ข้อมูลข่าวสารต่างๆ
              รวมทั้งติดต่อแก้ไขปัญหาการใช้งานระบบภายใต้ชื่อ Wesgan
              หรือตามที่กฎหมายกำหนด ทั้งนี้ หากมีการเปลี่ยนแปลงวัตถุประสงค์
              บริษัทฯ จะแจ้งให้เจ้าของข้อมูลส่วนบุคคลทราบ
              และบันทึกเพิ่มเติมไว้เป็นหลักฐาน
              รวมทั้งปฏิบัติให้เป็นไปตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล
            </p>
          </div>
        </div>
        <div className="personal-info-collection privacy-topic-container">
          <div className="privacy-header-text">
            <h4>3. การประมวลผลข้อมูลส่วนบุคคล</h4>
          </div>
          <div className="body-text">
            <p>
              3.1 การเก็บรวบรวมข้อมูลส่วนบุคคล
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;บริษัทฯ
              จะเก็บรวบรวมข้อมูลส่วนบุคคลอย่างจำกัดและเท่าที่จำเป็น
              โดยขึ้นอยู่กับประเภทของบริการที่เจ้าของข้อมูลส่วนบุคคลใช้บริการหรือให้ข้อมูลส่วนบุคคลกับ
              บริษัทฯ เช่น การแจ้งปัญหาการใช้งาน ที่ผ่านโดยตรงที่
              support@wesgan.com
              ซึ่งจะมีการเก็บรวบรวมข้อมูลส่วนบุคคลดังกล่าวเท่าที่จำเป็นเท่านั้น
            </p>
            <p>
              3.2 การเปิดเผยข้อมูลส่วนบุคคล
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;บริษัทฯ
              จะใช้ข้อมูลส่วนบุคคลตามวัตถุประสงค์ที่เจ้าของข้อมูลส่วนบุคคลให้ไว้กับ
              บริษัทฯ
              โดยใช้อย่างเหมาะสมและมีมาตรการการรักษาความมั่นคงปลอดภัยและมีการควบคุมการเข้าถึงข้อมูลส่วนบุคคล
            </p>
            <p>
              3.3 การใช้ข้อมูลส่วนบุคคล
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;โดยปกติ บริษัทฯ
              จะไม่เปิดเผยข้อมูลส่วนบุคคล
              เว้นแต่เป็นการเปิดเผยตามวัตถุประสงค์ที่เจ้าของข้อมูลส่วนบุคคลได้ให้ไว้กับ
              บริษัทฯ เช่น
              เปิดเผยข้อมูลส่วนบุคคลเพื่อเป็นการปฏิบัติตามบริการที่เจ้าของข้อมูลส่วนบุคคลร้องขอ
              หรือเป็นไปตามภาระผูกพันตามสัญญา หรือตามที่กฎหมายกำหนดให้เปิดเผย
              และในกรณีใดๆ ที่บริษัทฯ ต้องการเก็บรวมรวม ใช้
              หรือเปิดเผยข้อมูลส่วนบุคคลเพิ่มเติมหรือมีการเปลี่ยนแปลงวัตถุประสงค์ในการเก็บรวบรวม
              ใช้ หรือเปิดเผย บริษัทฯ
              จะแจ้งให้เจ้าของข้อมูลส่วนบุคคลทราบก่อนที่จะดำเนินการกับข้อมูลส่วนบุคคลนั้น
              เว้นแต่เป็นกรณีที่กฎหมายกำหนดหรืออนุญาตให้ดำเนินการได้
            </p>
          </div>
        </div>
        <div className="personal-info-collection privacy-topic-container">
          <div className="privacy-header-text">
            <h4>4. ระยะเวลาในการเก็บรักษาข้อมูลส่วนบุคคล</h4>
          </div>
          <div className="body-text">
            <p>
              บริษัทฯ จะเก็บรักษาข้อมูลส่วนบุคคลตราบเท่าที่จำเป็นต่อการประมวลผล
              และเมื่อพ้นระยะเวลาดังกล่าวแล้ว บริษัทฯ
              จะดำเนินการทำลายข้อมูลส่วนบุคคล
            </p>
          </div>
        </div>
        <div className="personal-info-collection privacy-topic-container">
          <div className="privacy-header-text">
            <h4>5. สิทธิของเจ้าของข้อมูลส่วนบุคคล</h4>
          </div>
          <div className="body-text">
            <p>
              ความยินยอมที่เจ้าของข้อมูลส่วนบุคคลให้ไว้กับ บริษัทฯ
              ในการเก็บรวบรวม ใช้
              และเปิดเผยข้อมูลส่วนบุคคลยังคงใช้ได้จนกว่าเจ้าของข้อมูลส่วนบุคคลจะเพิกถอนความยินยอมเป็นลายลักษณ์อักษร
              โดยเจ้าของข้อมูลส่วนบุคคลสามารถเพิกถอนความยินยอมหรือระงับการใช้หรือเปิดเผยข้อมูลส่วนบุคคล
              เพื่อวัตถุประสงค์ในการดำเนินกิจกรรมใดๆ หรือทุกกิจกรรมของ บริษัทฯ
              โดยส่งคำขอของเจ้าของข้อมูลส่วนบุคคลแจ้งให้ บริษัทฯ
              ทราบเป็นลายลักษณ์อักษรหรือผ่านทางจดหมายอิเล็กทรอนิกส์
            </p>
            <p className="privacy-indent">
              นอกจากสิทธิดังกล่าวข้างต้น
              เจ้าของข้อมูลส่วนบุคคลยังมีสิทธิในการดำเนินการ ดังต่อไปนี้
            </p>
            <p className="privacy-indent">
              (1) สิทธิในการเพิกถอนความยินยอม (right to withdraw consent)
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;เจ้าของข้อมูลส่วนบุคคลมีสิทธิเพิกถอนความยินยอมในการประมวลผลข้อมูลส่วนบุคคลที่เจ้าของข้อมูลส่วนบุคคลได้ให้ความยินยอมไว้แก่
              บริษัทฯ ได้ตลอดระยะเวลาที่ข้อมูลส่วนบุคคลของตนอยู่กับ บริษัทฯ
            </p>
            <p className="privacy-indent">
              (2) สิทธิในการเข้าถึงข้อมูลส่วนบุคคล (right of access)
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;เจ้าของข้อมูลส่วนบุคคลมีสิทธิเข้าถึงข้อมูลส่วนบุคคลของตนและขอให้
              บริษัทฯ ทำสำเนาข้อมูลส่วนบุคคลดังกล่าวให้แก่เจ้าของข้อมูลส่วนบุคคล
              รวมถึงขอให้ บริษัทฯ
              เปิดเผยการได้มาซึ่งข้อมูลส่วนบุคคลที่เจ้าของข้อมูลส่วนบุคคลไม่ได้ให้ความยินยอมต่อ
              บริษัทฯ ได้
            </p>
            <p className="privacy-indent">
              (3) สิทธิในการแก้ไขข้อมูลส่วนบุคคลให้ถูกต้อง (right to
              rectification)
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;เจ้าของข้อมูลส่วนบุคคลมีสิทธิขอให้ บริษัทฯ
              แก้ไขข้อมูลส่วนบุคคลที่ไม่ถูกต้อง
              หรือเพิ่มเติมข้อมูลส่วนบุคคลที่ไม่สมบูรณ์
            </p>
            <p className="privacy-indent">
              (4) สิทธิในการลบข้อมูลส่วนบุคคล (right to erasure)
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;เจ้าของข้อมูลส่วนบุคคลมีสิทธิในการขอให้
              บริษัทฯ ทำการลบข้อมูลส่วนบุคคลของตนด้วยเหตุบางประการได้
            </p>
            <p className="privacy-indent">
              (5) สิทธิในการระงับการใช้ข้อมูลส่วนบุคคล (right to restriction of
              processing)
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;เจ้าของข้อมูลส่วนบุคคลมีสิทธิระงับการใช้ข้อมูลส่วนบุคคลของตนด้วยเหตุบางประการได้
            </p>
            <p className="privacy-indent">
              (6) สิทธิในการให้โอนย้ายข้อมูลส่วนบุคคล (right to data
              portability)
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;เจ้าของข้อมูลส่วนบุคคลมีสิทธิให้ บริษัทฯ
              โอนย้ายข้อมูลส่วนบุคคลซึ่งเจ้าของข้อมูลส่วนบุคคลได้ให้ไว้กับ
              บริษัทฯ ไปยังผู้ควบคุมข้อมูลส่วนบุคคลรายอื่น
              หรือตัวเจ้าของข้อมูลส่วนบุคคลเองด้วยเหตุบางประการได้
            </p>
            <p className="privacy-indent">
              (7) สิทธิในการคัดค้านการประมวลผลข้อมูลส่วนบุคคล (right to object)
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;เจ้าของข้อมูลส่วนบุคคลมีสิทธิคัดค้านการประมวลผลข้อมูลส่วนบุคคลของตนด้วยเหตุบางประการได้
            </p>
            <p>
              บริษัทฯ
              เคารพการตัดสินใจการเพิกถอนความยินยอมของเจ้าของข้อมูลส่วนบุคคล
              แต่อย่างไรก็ตาม บริษัทฯ
              ขอแจ้งให้เจ้าของข้อมูลส่วนบุคคลทราบว่าอาจมีข้อจำกัดสิทธิในการเพิกถอนความยินยอมโดยกฎหมายหรือสัญญาที่ให้ประโยชน์แก่เจ้าของข้อมูลส่วนบุคคล
              ทั้งนี้ การเพิกถอนความยินยอมย่อมไม่ส่งผลกระทบต่อการเก็บรวบรวม ใช้
              หรือเปิดเผยข้อมูลส่วนบุคคลที่เจ้าของข้อมูลส่วนบุคคลได้ให้ความยินยอมไว้แล้ว
            </p>
          </div>
        </div>
        <div className="personal-info-collection privacy-topic-container">
          <div className="privacy-header-text">
            <h4>6. การรักษาความมั่นคงปลอดภัยสำหรับข้อมูลส่วนบุคคล</h4>
          </div>
          <div className="body-text">
            <p>
              บริษัทฯ จัดให้มีมาตรการการรักษาความมั่นคงปลอดภัยที่เหมาะสม
              เพื่อป้องกัน การเข้าถึง การใช้ การเปลี่ยนแปลง การแก้ไข
              หรือการเปิดเผยข้อมูลส่วนบุคคลโดยปราศจากอำนาจหรือโดยมิชอบ นอกจากนี้
              บริษัทฯ ได้กำหนดแนวปฏิบัติภายใน บริษัทฯ
              เพื่อกำหนดสิทธิในการเข้าถึงหรือการใช้ข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคล
              เพื่อรักษาความลับและความปลอดภัยของข้อมูล และ บริษัทฯ
              จะจัดให้มีการทบทวนมาตรการดังกล่าวเป็นระยะเพื่อความเหมาะสม
            </p>
          </div>
        </div>
        <div className="personal-info-collection privacy-topic-container">
          <div className="privacy-header-text">
            <h4>7. การใช้คุกกี้ (Cookies)</h4>
          </div>
          <div className="body-text">
            <p>
              บริษัทฯ จัดให้มีมาตรการการรักษาความมั่นคงปลอดภัยที่เหมาะสม
              เพื่อป้องกัน การเข้าถึง การใช้ การเปลี่ยนแปลง การแก้ไข
              หรือการเปิดเผยข้อมูลส่วนบุคคลโดยปราศจากอำนาจหรือโดยมิชอบ นอกจากนี้
              บริษัทฯ ได้กำหนดแนวปฏิบัติภายใน บริษัทฯ
              เพื่อกำหนดสิทธิในการเข้าถึงหรือการใช้ข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคล
              เพื่อรักษาความลับและความปลอดภัยของข้อมูล และ บริษัทฯ
              จะจัดให้มีการทบทวนมาตรการดังกล่าวเป็นระยะเพื่อความเหมาะสม
            </p>
            <p>
              คุกกี้ (Cookies) หมายถึง
              ข้อมูลขนาดเล็กที่เว็บไซต์ส่งไปเก็บไว้กับเจ้าของข้อมูลส่วนบุคคลที่เข้าชมเว็บไซต์
              เพื่อช่วยให้เว็บไซต์จดจำข้อมูลเข้าชมของเจ้าของข้อมูลส่วนบุคคล เช่น
              ภาษาที่เลือกใช้เป็นอันดับแรก ผู้ใช้ของระบบ หรือการตั้งค่าอื่นๆ
              เมื่อเจ้าของข้อมูลส่วนบุคคลเข้าชมเว็บไซต์ในครั้งถัดไป
              เว็บไซต์จะจดจำได้ว่าเป็นผู้ใช้ที่เคยเข้าใช้บริการแล้ว
              และตั้งค่าตามที่เจ้าของข้อมูลส่วนบุคคลกำหนด
              จนกว่าเจ้าของข้อมูลส่วนบุคคลจะลบคุกกี้ (Cookies)
              หรือไม่อนุญาตให้คุกกี้ (Cookies) นั้นทำงานอีกต่อไป
              ซึ่งเจ้าของข้อมูลส่วนบุคคลสามารถที่จะยอมรับหรือไม่รับคุกกี้
              (Cookies) ก็ได้ ในกรณีที่เลือกที่จะไม่รับหรือลบคุกกี้ (Cookies)
              เว็บไซต์อาจจะไม่สามารถให้บริการหรือไม่สามารถแสดงผลได้อย่างถูกต้อง
            </p>
          </div>
        </div>
        <div className="personal-info-collection privacy-topic-container">
          <div className="privacy-header-text">
            <h4>8. การปรับปรุงนโยบายการคุ้มครองข้อมูลส่วนบุคคล</h4>
          </div>
          <div className="body-text">
            <p>
              บริษัทฯ อาจทำการปรับปรุงหรือแก้ไขนโยบายการคุ้มครองข้อมูลส่วนบุคคล
              โดยมิต้องแจ้งให้เจ้าของข้อมูลส่วนบุคคลทราบล่วงหน้า ทั้งนี้
              เพื่อความเหมาะสมและมีประสิทธิภาพในการให้บริการ ดังนั้น บริษัทฯ
              จึงขอแนะนำให้เจ้าของข้อมูลส่วนบุคคลอ่านนโยบายการคุ้มครองข้อมูลส่วนบุคคลทุกครั้งที่เยี่ยมชมหรือใช้บริการจากบริษัทฯ
              หรือเว็บไซต์ของบริษัทฯ
            </p>
          </div>
        </div>
        <div className="personal-info-collection privacy-topic-container">
          <div className="privacy-header-text">
            <h4>
              9.
              การปฏิบัติตามนโยบายคุ้มครองข้อมูลส่วนบุคคลและการติดต่อกับบริษัทฯ
            </h4>
          </div>
          <div className="body-text">
            <p>
              ในกรณีที่เจ้าของข้อมูลส่วนบุคคลมีข้อสงสัยหรือข้อเสนอแนะเกี่ยวกับนโยบายการคุ้มครองข้อมูลส่วนบุคคลหรือการปฏิบัติตามนโยบายการคุ้มครองข้อมูลส่วนบุคคลฉบับนี้
              บริษัทฯ ยินดีตอบข้อสงสัย และรับฟังข้อเสนอแนะ
              เพื่อประโยชน์ต่อการปรับปรุงการคุ้มครองข้อมูลส่วนบุคคลและการให้บริการของ
              บริษัทฯ ต่อไป โดยเจ้าของข้อมูลส่วนบุคคลสามารถติดต่อกับ บริษัทฯ
              ได้ที่ support@wesgan.com หรือตามที่อยู่ด้านล่างนี้
            </p>
          </div>
        </div>
        <div className="personal-info-collection privacy-topic-container">
          <div className="privacy-header-text">
            <h4>บริษัท ดิจิทอล เอไอ จำกัด</h4>
          </div>
          <div className="body-text">
            <p>
              314 หมู่ที่ 7 ตำบลสุเทพ อำเภอเมืองเชียงใหม่ จังหวัดเชียงใหม่ 50200
              <br />
              Website: https://www.wesgan.com
              <br />
              e-mail: support@wesgan.com
            </p>
          </div>
          <div className="body-text text-end">
            <p>
              PDPA นโยบายคุ้มครองข้อมูลส่วนบุคคล
              <br />
              ฉบับปรับปรุง เมษายน 2567
            </p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export { PrivacyPolicyPage };
